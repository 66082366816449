<template>
  <div>
    <div class="form-entry-container" style="margin: 4px;">
      <div class="form-entry-label">
        Excel file name
      </div>
      <div class="form-entry-input" style="margin: 4px;">
        <kendo-upload
          :ref="'uploadFile'"
          :localization-select="'Select...'">
        </kendo-upload>

        <button @click="onContinue()">Continue...</button>
      </div>
      <div class="form-entry-label">
        Date when the review was conducted
      </div>
      <div class="form-entry-input" style="margin: 4px;">
        <input type="text">
      </div>
      <div></div>
      <div class="form-entry-label" style="padding: 10px" v-if="this.continue">
        Import citations
      </div>
      <div class="form-entry-input" v-if="this.continue">
        <div style="display: flex; align-items: center;">
          <input name="radioAllSelected" type="radio"><b style="padding: 5px;">All</b>
          <input name="radioAllSelected" type="radio"><b style="padding: 5px;">Selected</b>
        </div>
        <div>Import none excluded citations to stage:
          <select>
            <option>Select...</option>
            <option>Abstract Screening</option>
            <option>Full Text Screening</option>
            <option>Full Data Extraction</option>
            <option>Current Stage</option>
          </select>
        </div>
        <div><input type="checkbox">Import reasons for exclusions</div>
        <div><input type="checkbox">Import comments</div>
        <div>
          <kendo-datasource
          :ref="'publicationDataSource'"
          :data="publicationDataSource.data"
          :page-size="'5'"
          :filter="filter">
        </kendo-datasource>
        <kendo-grid
          :ref="'importGridCitations'"
          :data-source-ref="'publicationDataSource'"
          style="width: calc(100vw - 20px);"
          :editable="editable"
          :sortable="sortable"
          :scrollable="scrollable"
          :selectable="selectable"
          :toolbar="toolbar"
          :resizable="resizable"
          :filterable="filterable"
          :columns="citationColumns"
          :messages="messages">
        </kendo-grid>
        </div>
      </div>
      <!-- <div class="form-entry-label" v-if="this.continue">
        Import studies
      </div> -->
      <!-- <div class="form-entry-input" v-if="this.continue">
        <div style="display: flex; align-items: flex-end; align-items: center; width:auto">
          <input name="radioAllSelected2" type="radio"><b style="padding: 5px;">All</b>
          <input name="radioAllSelected2" type="radio"><b style="padding: 5px;">Selected</b>
          <input name="radioAllSelected2" type="radio"><b style="padding: 5px;">Only those matching imported citations</b>
        </div>
        <div><input type="checkbox">Match imported Studies to Citation as indicated in original file</div>
        <div>
          <kendo-datasource
          :ref="'studyDataSource'"
          :data="studyDataSource.data"
          :page-size="'8'">
        </kendo-datasource>
        <kendo-grid
          :ref="'importGridStudies'"
          :data-source-ref="'studyDataSource'"
          style="width: calc(100vw - 20px);"
          :editable="editable"
          :sortable="sortable"
          :scrollable="scrollable"
          :selectable="selectable"
          :toolbar="toolbar"
          :resizable="resizable"
          :filterable="filterable"
          :columns="studyColumns"
          :messages="messages">
        </kendo-grid>
        </div>
      </div> -->
    </div>
    <div style="display: flex; justify-content: flex-end; width: 100%">
        <button v-if="this.continue">Import</button>
        <button @click="onCancel()">Cancel</button>
      </div>
  </div>
</template>

<script>

import PublicationDataSource from '@/assets/data/Citation.json'
import StudyDataSource from '@/assets/data/Study.json'

export default {
  name: 'import-project',
  methods: {
    onContinue: function () {
      this.continue = true
    },
    onCancel: function () {
      this.continue = false
    }
  },
  data () {
    return {
      continue: false,
      filter: {
        logic: 'and',
        filters: [
          { field: 'StageIdentity', operator: 'eq', value: '2' }
        ]
      },
      selectable: 'multiple, row',
      citationColumns: [
        {
          title: 'Citation Fields',
          headerAttributes: { class: 'grid-header-de1' },
          columns: [
            { field: 'PublicationIdentity', title: 'Citation ID', width: '120px', headerAttributes: { class: 'grid-header-de1' }, editable: false },
            { field: 'CurrentStage', title: 'Current Stage', width: '120px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Author', title: 'Author', width: '120px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Year', title: 'Year', width: '80px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Title', title: 'Title', template: 'Randomized comparison of cis-diammined...', width: '300px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Abstract', title: 'Abstract', template: 'Fifty-seven patients with recurrent squa...', width: '400px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'TypeOfPublication', title: 'Type of Publication', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Volume', title: 'Volume', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Issue', title: 'Issue', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Pages', title: 'Page(s)', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'ConferenceName', title: 'Conference Name', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'ConferenceDate', title: 'Date(s) of Conference', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'ReportName', title: 'Report Name', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'OrganizingBody', title: 'Organizing Body', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'ReportDate', title: 'Date of Report', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'DatabaseName', title: 'Name of Database', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'AccessionNumber', title: 'Accession Number', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'DOI', title: 'DOI', template: 'http://dx.doi.org/10.1016/...', width: '200px', headerAttributes: { class: 'grid-header-de1' } }
          ]
        }
      ],
      studyColumns: [
        {
          title: 'Citation Fields',
          headerAttributes: { class: 'grid-header-de1' },
          columns: [
            { field: 'StudyID', title: 'Selected Study ID', filterable: false, headerAttributes: { class: 'grid-header-dark' } },
            { field: 'StudyAcronym', title: 'Acronym', filterable: false, headerAttributes: { class: 'grid-header-dark' } },
            { field: 'StudyDescription', title: 'Description', filterable: false, headerAttributes: { class: 'grid-header-dark' } },
            { field: 'PrimaryPublication', title: 'Is Main Citation', template: '<input type="checkbox">', filterable: false, headerAttributes: { class: 'grid-header-dark' } }
          ]
        }
      ],
      publicationDataSource: PublicationDataSource,
      studyDataSource: StudyDataSource
    }
  }
}
</script>
